<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">

			<!-- Brand logo-->
			<b-link class="brand-logo">
				<!-- <vuexy-logo /> -->
				<h2 class="brand-text text-primary ml-1">
					Vui88
				</h2>
			</b-link>
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col
				lg="8"
				class="d-none d-lg-flex align-items-center p-5"
			>
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img
						fluid
						:src="imgUrl"
						alt="Login V2"
					/>
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col
				lg="4"
				class="d-flex align-items-center auth-bg px-2 p-lg-5"
			>
				<b-col
					sm="8"
					md="6"
					lg="12"
					class="px-xl-2 mx-auto"
				>
					<b-card-title
						class="mb-1 font-weight-bold"
						title-tag="h2"
					>
						{{ $t('Welcome to Vui88') }}! 👋
					</b-card-title>
					<b-card-text class="mb-2">
						{{ $t('Please login and enjoy!') }}
					</b-card-text>

					<!-- form -->
					<validation-observer
						ref="loginForm"
						#default="{invalid}"
					>
						<b-form
							class="auth-login-form mt-2"
							@submit.prevent="login"
						>
							<!-- email -->
							<b-form-group
								label-for="login-email"
							>
								<validation-provider
									#default="{ errors }"
									name="Email"
									vid="email"
									rules="required"
								>
									<b-form-input
										id="login-email"
										v-model="userEmail"
										:state="errors.length > 0 ? false:null"
										name="login-email"
										:placeholder="$t('Username')"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- forgot password -->
							<b-form-group>
								<validation-provider
									#default="{ errors }"
									name="Password"
									vid="password"
									rules="required"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid':null"
									>
										<b-form-input
											id="login-password"
											v-model="password"
											:state="errors.length > 0 ? false:null"
											class="form-control-merge"
											:type="passwordFieldType"
											name="login-password"
											:placeholder="$t('Password')"
										/>
										<b-input-group-append is-text>
											<feather-icon
												class="cursor-pointer"
												:icon="passwordToggleIcon"
												@click="togglePasswordVisibility"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- checkbox -->
							<b-form-group>
								<b-form-checkbox
									id="remember-me"
									v-model="status"
									name="checkbox-1"
								>
									{{ $t('Remember password') }}
								</b-form-checkbox>
							</b-form-group>

							<!-- submit buttons -->
							<b-button
								v-if="!loading"
								type="submit"
								variant="primary"
								block
								:disabled="invalid"
							>
								{{ $t('Sign in') }}
							</b-button>
							<b-button v-else variant="primary" disabled block>
								<b-spinner small />
							</b-button>
						</b-form>
					</validation-observer>
				</b-col>
			</b-col>
			<!-- /Login-->
		</b-row>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
	BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip, BSpinner
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import jwt from 'jsonwebtoken'
import instance from '@/libs/axios.instance'

export default {
	directives: {
		'b-tooltip': VBTooltip,
	},
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		BAlert,
		VuexyLogo,
		ValidationProvider,
		ValidationObserver,
		BSpinner
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			status: '',
			password: '',
			userEmail: '',
			userData: {
				id: '',
				fullName: '',
				username: '',
				avatar: require('@/assets/images/avatars/13-small.png'),
				email: '',
				role: 'admin',
				roleName: '',
				roleId: '',
				ability: [
					{
						action: 'manage',
						subject: 'all',
					},
				],
				extras: {
					eCommerceCartItemsCount: 5,
				},
			},
			jwtConfig: {
				secret: '',
				refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
				expireTime: '10m',
				refreshTokenExpireTime: '10m',
			},
			sideImg: require('@/assets/images/pages/login-v2.svg'),
			// validation rules
			required,
			email,
			ability: [],
			loading: false,
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		imgUrl() {
			if (store.state.appConfig.layout.skin === 'dark') {
				this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
	},
	mounted() {
		const users = [
			{
				action: 'view',
				subject: 'adjustbalance',
			},
			{
				action: 'create',
				subject: 'adjustbalance'
			},
			{
				action: 'view',
				subject: 'balance'
			},
		]
	},
	methods: {
		login() {
			const FormData = require('form-data')
			const data = new FormData()
			data.append('username', this.userEmail)
			data.append('password', this.password)
			this.$refs.loginForm.validate().then(success => {
				if (success) {
					this.loading = true;
					instance.post('/api/public/login', data)
						.then(response => {
							if (response.status == 200) {
								if (response.data.code == '00') {
									instance.get(`/api/staff/detail?staffId=${response.data.data.user_id}`, { headers: { Authorization: `Bearer ${response.data.data.token}` } })
										.then(res => {
											// this.userData.roleName = res.data.staffDto.roles.map(role => role.name).toString()
											// this.userData.roleId = parseInt(res.data.staffDto.roles.map(role => role.id).toString())
											const datasF = []
											// const functionsEncode = res.data.functions.map(item => btoa(item.action))
											res.data.data.functions.map(i => {
												const a = i.action.split('_').join('')
												if (a.indexOf('view') > -1) {
													const b = a.replace('view', '')
													datasF.push({
														action: 'view',
														subject: b
													})
												} else if (a.indexOf('update') > -1) {
													const c = a.replace('update', '')
													datasF.push({
														action: 'update',
														subject: c
													})
												} else if (a.indexOf('create') > -1) {
													const d = a.replace('create', '')
													datasF.push({
														action: 'create',
														subject: d
													})
												} else if (a.indexOf('change') > -1) {
													const f = a.replace('change', '')
													datasF.push({
														action: 'change',
														subject: f
													})
												} else if (a.indexOf('edit') > -1) {
													const j = a.replace('edit', '')
													datasF.push({
														action: 'edit',
														subject: j
													})
												} else if (a.indexOf('delete') > -1) {
													const h = a.replace('delete', '')
													datasF.push({
														action: 'delete',
														subject: h
													})
												} else if (a.indexOf('import') > -1) {
													const h = a.replace('import', '')
													datasF.push({
														action: 'import',
														subject: h
													})
												} else if (a.indexOf('export') > -1) {
													const h = a.replace('export', '')
													datasF.push({
														action: 'export',
														subject: h
													})
												} else {
													// console.log(a.indexOf('view'))
													datasF.push({
														action: 'read',
														subject: a
													})
												}
											})
											// localStorage.setItem('userFunctions', JSON.stringify(functionsEncode))
											// console.log(this.groupBy(datasF))
											const datasFunctions = this.groupBy(datasF)
											const df = [...datasFunctions, { subject: 'Auth', action: 'read' }]
											localStorage.setItem('userFunctions', JSON.stringify(df))
											this.$ability.update(df)
										})
									this.loading = false;
									this.userData.id = response.data.data.id
									this.userData.fullName = response.data.data.username
									this.userData.username = response.data.data.username
									this.userData.email = response.data.data.email
									localStorage.setItem('token', response.data.data.token)
									this.jwtConfig.secret = response.data.data.token
									const accessToken = jwt.sign({ id: this.userData.id }, this.jwtConfig.secret, { expiresIn: this.jwtConfig.expireTime })
									useJwt.setToken(accessToken)

									localStorage.setItem('userData', JSON.stringify(this.userData))
									// this.$ability.update(this.userData.ability)

									this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.userData.extras.eCommerceCartItemsCount)

									this.$router.replace(getHomeRouteForLoggedInUser(this.userData.role))
										.then(() => {
											this.$toast({
												component: ToastificationContent,
												position: 'top-right',
												props: {
													title: `Welcome ${this.userData.fullName || this.userData.username}`,
													icon: 'CoffeeIcon',
													variant: 'success',
													// text: `You are logging with ${this.userData.roleName} role.`,
												},
											})
										})
								} else {
									this.loading = false;
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							} else {
								this.loading = false;
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Vui lòng f5 để thử lại hoặc liên hệ với bộ phận IT',
										icon: 'AlertTriangleIcon',
										variant: 'danger',
									},
								})
							}
						})
						.catch(error => {
							this.loading = false;
							const { response } = error
							// this.$refs.loginForm.setErrors(response.data.message)
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Vui lòng f5 để thử lại hoặc liên hệ với bộ phận IT',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		groupBy(arr) {
			return Object.values(
				arr.reduce((acc, {action, subject}) => {
					acc[subject] = {subject,action: (acc[subject]?.action || []).concat([action])}
					return acc;
				}, {}))
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
